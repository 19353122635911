import { useContext, CSSProperties } from "react";



import { useIsMobile } from "../functions/isMobile";
import { Context } from "../functions/context";

import {
  MultiChatWindow,
  MultiChatSocket,
  useMultiChatLogic,
  MessageFormProps,
  ChatCardProps,
  ChatHeaderProps,

  
} from "react-chat-engine-advanced";

import "../theme.css";

import Sidebar from "./Sidebar";
import MessageForm from "./MessageForm";
import UserSearch from "./UserSearch";
import ChatCard from "./ChatCard";
import ChatHeader from "./ChatHeader";

import { projectId } from "../functions/constants";

const ChatsPage = () => {
  // Hooks
  const { user } = useContext(Context);
  const isMobile: boolean = useIsMobile();

  // Chat Engine Hooks
  const username: string = user ? user.username : "";
  const secret: string = user && user.secret !== null ? user.secret : "";
  const chatProps = useMultiChatLogic(projectId, username, secret);
  //const offset = ""






  return (
    <div className="background-gradient-light" style={{ height: "100vh" }}>
      <div
        style={{
          position: "fixed",
          top: isMobile ? "0px" : "0vh",
          left: isMobile ? "100px" : "0vw",
          height: "100%", // Fill parent height
        }}
      >
        <div
          style={{
            width: "6vw",
            height: "100%",
            position: "fixed",
            top: "0px",
            left: "0px",
            backgroundColor: "rgb(40,43,54)",
          }}
        >
          <Sidebar />

        </div>
  
        <div
          style={{
            width: isMobile ? "100vw" : "calc(100vw - 6vw)",
            position: "fixed",
            top: "0px",
            left: isMobile ? "0px" : "5vw",
            height: "100%", // Fill parent height
          }}
        >
          
          <MultiChatSocket {...chatProps} />

          
          
          <MultiChatWindow
            {...chatProps}
            
            
            renderChatForm={() => (
              <UserSearch
                username={chatProps.username}
                secret={chatProps.secret}
                
                onSelect={(chatId: number) =>
                  chatProps.onChatCardClick(chatId)
                }
                
              />
              
            )}
            
            renderChatCard={(props: ChatCardProps) => (
              <ChatCard
                {...props}
                username={chatProps.username}
                onChatCardClick={chatProps.onChatCardClick}
                
                isActive={
                  props.chat !== undefined &&
                  chatProps.activeChatId === props.chat.id
                }
                chat={props.chat}

              />
            )}
            renderChatHeader={(props: ChatHeaderProps) => (
              <ChatHeader
                {...props}
                chat={chatProps.chat}
                username={chatProps.username}
                secret={chatProps.secret}
              />
            )}
            renderMessageForm={(props: MessageFormProps) => (
              <MessageForm {...props} />
            )}
            renderChatSettings={() => <></>}
            

            //style={{ height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
  
};

export default ChatsPage;
